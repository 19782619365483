<template>
	<div>
		<div class="md:flex md:items-center md:justify-between mb-5">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					Detours
				</h2>
			</div>
			<div class="mt-4 flex md:mt-0 md:ml-4">
				<RefreshTableButton @refreshTable="refreshTable" />
				<div class="mt-3 sm:mt-0 sm:ml-4">
					<button
						type="button"
						class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
						@click="openSlide"
					>
						Add Detour
					</button>
				</div>
			</div>
		</div>

		<div class="flex flex-col">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<ag-grid-vue
						style="height: 725px"
						class="ag-theme-alpine"
						:grid-options="gridOptions"
						:column-defs="columnDefs"
						:default-col-def="defaultColDef"
						:row-data="rowData"
						:modules="modules"
						:pagination="true"
						:pagination-page-size="50"
						:enable-cell-text-selection="true"
						:ensure-dom-order="true"
					></ag-grid-vue>
				</div>
			</div>
		</div>
		<!-- :edit="edit" -->
		<SlidePanel
			slide-title="Create Detour"
			slide-component="ContentDetourItemSelect"
			:open="slideOpen"
			@closeSlide="closeSlide"
			@createDetour="createDetour"
		/>
	</div>
</template>

<script>
import { inject } from 'vue'
import { useToast } from 'vue-toastification'

import { AgGridVue } from '@ag-grid-community/vue3'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import TableActionsById from '@/components/TableActionsById.vue'

import { PlusIcon } from '@heroicons/vue/solid'
import getEnv from '@/extensions/env'

export default {
	name: 'News',
	components: {
		AgGridVue,
		// PlusIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
			frontendUrl: getEnv('VUE_APP_FRONTEND_URL'),
		}
	},
	data() {
		return {
			columnDefs: null,
			rowData: null,
			defaultColDef: null,
			slideOpen: false,
			modules: [ClientSideRowModelModule],
		}
	},
	beforeMount() {
		this.gridOptions = {
			context: {
				componentParent: this,
			},
		}

		this.columnDefs = [
			// { field: "id", minWidth: 310 },
			{
				field: 'type',
				headerName: 'Created By',
				flex: 0,
			},
			{ field: 'from', headerName: 'Inbound URL', flex: 1 },
			{
				field: 'to',
				headerName: 'Destination',
				flex: 1,
				cellRenderer: params => {
					return '<a href="' + this.frontendUrl + params.value + '">' + params.value + '</a>'
				},
			},
			{
				headerName: 'Actions',
				width: 300,
				cellRendererFramework: TableActionsById,
				cellRendererParams: {
					viewRoute: 'content-detours-id',
					showView: false,
					showClone: false,
					showDelete: true,
					deleteApi: 'detours',
					deleteTitle: 'Delete Detour',
					deleteContent: 'Are you sure you want to delete this detour?',
					deleteRoute: 'content-detours',
					appApi: this.api,
					parent: this,
					newTab: false,
				},
			},
		]

		this.defaultColDef = {
			resizable: true,
			sortable: true,
			filter: 'agTextColumnFilter',
			floatingFilter: true,
		}
	},
	mounted() {
		this.gridApi = this.gridOptions.api
		this.fetchDetours()
	},
	methods: {
		fetchDetours(reload = false) {
			if (reload) {
				this.gridApi.showLoadingOverlay()
			}
			this.loaderShow()

			this.api.detours
				.all()
				.then(data => {
					console.log('data: ', data)
					this.rowData = data
				})
				.catch(error => {
					this.toast.error('Error fetching detours:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
					if (reload) {
						this.gridApi.hideOverlay()
					}
				})
		},

		refreshTable() {
			this.fetchDetours(true)
		},

		booleanFormatter(params) {
			return params.value == '0' ? 'No' : 'Yes'
		},

		openSlide() {
			this.slideOpen = true
		},

		closeSlide() {
			this.slideOpen = false
		},
		createDetour(detour) {
			console.log('Create Detour on detour page. Detour: ', detour)
			this.loaderShow()

			// console.log('Detour API:', this.api.detours)
			this.api.detours
				.store(detour)
				.then(data => {
					console.log(data)
					this.toast.success('Successfully created detour')
					this.closeSlide()
					this.refreshTable()
				})
				.catch(error => {
					let message = ''
					// console.error('Error Data:', error.response.data)
					for (const err in error.response.data.errors) {
						message += error.response.data.errors[err].join('\n')
					}
					this.toast.error(
						'Error creating new detour:\n' + (message == '' ? error.response.data.message : message)
					)

					// if (error.response.data.errors) this.toast.error(error.response.data.errors.values().join('\n'))
				})
				.then(() => {
					this.loaderHide()
				})
		},
	},
}
</script>
